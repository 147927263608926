import {MuiSvgIcon, MuiTypography, MuiBox, MuiInputAdornment, MuiTextField} from '@alation/fabric';
import {ConnectorDistributionIcon, SearchIcon} from '@alation/icons';
import React, {ChangeEvent, FC} from 'react';
import './SearchInputView.less';

export interface SearchInputViewProps {
  readonly onSearchInputChange: (e: ChangeEvent) => void;
  readonly ref: any;
  readonly showSearchFilter: boolean;
}

export const SearchInputView: FC<SearchInputViewProps> = React.forwardRef(
  ({onSearchInputChange, showSearchFilter}, ref) => {
    return (
      <>
        <div className='header'>
          <div className='title'>
            <MuiBox>
              <MuiTypography
                component='h1'
                marginBottom={0.5}
                marginTop={7}
                sx={{fontFamily: 'AtlasGrotesk-Bold'}}
                variant='h1'>
                <MuiSvgIcon
                  component={ConnectorDistributionIcon}
                  data-testid='connectors-logo'
                  style={{fontSize: '5rem', paddingRight: '20px'}}
                />
                Alation Connector Hub
              </MuiTypography>
              <MuiTypography marginBottom={2.1} marginLeft={8.7} sx={{fontFamily: 'AtlasGrotesk-Bold'}} variant='body1'>
                Your source to download OCF connectors. Click on a tile to access the latest download file.
              </MuiTypography>
            </MuiBox>
          </div>
          {showSearchFilter ? (
            <div className='search-bar' data-testid='search-bar'>
              <MuiTextField
                InputProps={{
                  startAdornment: (
                    <MuiInputAdornment id='search-bar' position='start'>
                      <MuiSvgIcon component={SearchIcon} />
                    </MuiInputAdornment>
                  ),
                }}
                inputRef={ref}
                margin='normal'
                onChange={(event) => {
                  onSearchInputChange(event);
                }}
                sx={{
                  width: '520px',
                }}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  },
);
