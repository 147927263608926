import {debounce, some, isEmpty, pickBy, sortBy, isEqual} from 'lodash';
import {ChangeEvent, FC, useRef, useState, Dispatch, SetStateAction} from 'react';

import {SearchInputView} from './SearchInputView';
import {TagsFilterView} from './TagsFilterView';
import {ConnectorApi} from '../api/connector_api';
import {HomePageData, FilteredData} from '../models/connectors.interface';

export interface SearchAndFilterContainerViewProps {
  readonly homePageData: HomePageData;
  readonly setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  readonly setSearchResults: (data: undefined | FilteredData) => void;
  readonly showSearchFilter: boolean;
}

export const SearchAndFilterContainerView: FC<SearchAndFilterContainerViewProps> = ({
  homePageData,
  setSearchResults,
  showSearchFilter,
  setErrorMessage,
}) => {
  const [selectedValues, setSelectedValues] = useState({});
  const searchInput = useRef<any>(null);

  const handleConnectorSearchAndFilter = (): void => {
    const nameInputValue = Object.entries(selectedValues)
      .filter(([, val]) => val)
      .map(([key]) => key)
      .join(',');

    const hasSelectedValues = some(selectedValues);
    const searchString = searchInput?.current?.value;
    if (!hasSelectedValues && !searchString) {
      setSearchResults(undefined);
      return;
    }

    let url = new URL('/api/connector_portal/search/?', location.href).toString();
    let hasTags = false;
    if (!isEmpty(nameInputValue)) {
      url = `${url}tags=${nameInputValue}`;
      hasTags = true;
    }

    if (searchString) {
      url = `${url + (hasTags ? '&' : '')}q=${searchInput?.current?.value}`;
    }

    filterConnectors(url);
  };

  const filterConnectors = (url: string): void => {
    ConnectorApi.getFilteredConnectorData(url)
      .then((data: FilteredData) => {
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search);
        const tags = searchParams.get('tags');
        const q = searchParams.get('q');
        const selectedTags = tags?.split(',');
        const selectedValuesKeys = Object.keys(pickBy(selectedValues, (val) => !!val));
        const tagsAreEqual = isEqual(sortBy(selectedTags), sortBy(selectedValuesKeys));
        const queryIsEqual = isEqual(q ?? '', searchInput?.current?.value ?? '');

        if (tagsAreEqual && queryIsEqual) {
          setSearchResults(data);
        }
      })
      .catch((err: {response: {data: string}}) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value: any = selectedValues;
    value[event.target.value] = event.target.checked;
    setSelectedValues(value);
    handleConnectorSearchAndFilter();
  };

  const handleConnectorSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value || !isEmpty(selectedValues)) {
      handleConnectorSearchAndFilter();
    } else {
      setSearchResults(undefined);
    }
  };

  const [debouncedConnectorSearch]: any = useState(() => debounce(handleConnectorSearch, 250));

  return (
    <>
      <SearchInputView
        onSearchInputChange={(event: ChangeEvent) => debouncedConnectorSearch(event)}
        ref={searchInput}
        showSearchFilter={showSearchFilter}
      />
      <TagsFilterView
        homePageData={homePageData}
        onFilterChange={(event: ChangeEvent<HTMLInputElement>) => handleFilterChange(event)}
        showSearchFilter={showSearchFilter}
      />
    </>
  );
};
