// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
}
.tags {
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 285px;
  display: flex;
  padding-top: 20px;
  padding-bottom: 17px;
}
.tag-labels {
  font-size: 20px;
  height: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #000000;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/TagsFilterView.less"],"names":[],"mappings":"AAAA;;EAAa,YAAA;AAGb;AACA;EACE,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,oBAAA;AACF;AAEA;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAAF","sourcesContent":["html, body { height: 100%; }\n\n@drawer_width: 245px;\n\n.tags {\n  box-sizing: border-box;\n  border-radius: 4px;\n  margin-left: @drawer_width + 40px;\n  display: flex;\n  padding-top: 20px;\n  padding-bottom: 17px;\n}\n\n.tag-labels {\n  font-size: 20px;\n  height: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 16px;\n  color: #000000;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
