import {MuiSvgIcon, MuiTypography, MuiButton} from '@alation/fabric';
import {CaretRightSmallIcon} from '@alation/icons';
import React, {Dispatch, FC, SetStateAction} from 'react';

import {ConnectorGrid} from './ConnectorGrid';
import {ConnectorApi} from '../api/connector_api';
import {FilteredData} from '../models/connectors.interface';

export interface ConnectorSectionsViewProps {
  readonly homePageData: any;
  readonly setCategoryResults: (data: FilteredData) => void;
  readonly setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  readonly showDetailsView: (id: string) => void;
  readonly showViewAll: boolean;
}

const ifNotInstalledByOrg = (key: string): boolean => key !== 'Installed By Your Organization';

export const ConnectorSectionsView: FC<ConnectorSectionsViewProps> = ({
  showDetailsView,
  homePageData,
  setCategoryResults,
  showViewAll,
  setErrorMessage,
}) => {
  const handleOnShowAll = (key: string): void => {
    ConnectorApi.getCategoryWiseConnectorData(key)
      .then((data: FilteredData) => {
        setCategoryResults(data);
      })
      .catch((err: {response: {data: string}}) => {
        setErrorMessage(err.response.data);
      });
  };

  const generateSection = (key: string): any => (
    <>
      <MuiTypography
        data-testid={key}
        id={key}
        marginBottom={2.7}
        marginLeft={35.5}
        marginTop={9.8}
        sx={{fontFamily: 'AtlasGrotesk-Bold'}}
        variant='h5'>
        {key}
      </MuiTypography>
      <ConnectorGrid connectors={homePageData.all_connectors[key]} showDetailsView={showDetailsView} />
      {showViewAll ? (
        ifNotInstalledByOrg(key) ? (
          homePageData.all_connectors[key].length === 4 ? (
            <div className='view-all' id={key}>
              <MuiButton
                data-testid='view-all-btn'
                id='view-all-btn'
                onClick={() => {
                  handleOnShowAll(homePageData.all_connectors[key][0].category);
                }}
                sx={{width: 165, height: 43, backgroundColor: '#ffffff'}}
                variant='outlined'>
                <MuiTypography
                  sx={{
                    color: '#0075E3',
                    textAlign: 'center',
                    paddingRight: '8.2px',
                  }}
                  variant='h5'>
                  View All
                </MuiTypography>
                <MuiSvgIcon component={CaretRightSmallIcon} />
              </MuiButton>
            </div>
          ) : null
        ) : null
      ) : null}
    </>
  );
  return homePageData.all_connectors ? <> {Object.keys(homePageData.all_connectors).map(generateSection)} </> : <div />;
};
