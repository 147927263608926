// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
}
.title {
  margin-left: 285px;
  display: flex;
}
.search-bar {
  margin-left: 285px;
  border-radius: 8px;
  height: 50px;
  margin-bottom: 41px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchInputView.less"],"names":[],"mappings":"AAAA;;EAAa,YAAA;AAGb;AACA;EACE,kBAAA;EACA,aAAA;AACF;AAEA;EACE,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;AAAF","sourcesContent":["html, body { height: 100%; }\n\n@drawer_width: 245px;\n\n.title {\n  margin-left: @drawer_width + 40px;\n  display: flex;\n}\n\n.search-bar {\n  margin-left: @drawer_width + 40px;\n  border-radius: 8px;\n  height: 50px;\n  margin-bottom: 41px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
