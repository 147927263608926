import {fabricThemeClassic} from '@alation/fabric-theme-classic';
import {ThemeProvider} from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import {ConnectorDistributionPortalApp} from './components/ConnectorDistributionPortalApp';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={fabricThemeClassic}>
      <ConnectorDistributionPortalApp />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
