import {MuiTypography} from '@alation/fabric';
import React, {FC} from 'react';
import './ConnectorSectionView.less';

export interface TagsComponentProps {
  readonly tags: readonly string[];
}

const maxLength = 3;

export const TagsComponent: FC<TagsComponentProps> = ({tags}) => {
  //type can be either version-component or taggable-component (styled in the backend)
  let tags_count = undefined;
  if (tags.length - maxLength > 0) {
    tags_count = tags.length - maxLength;
  }
  const final_tags = tags.slice(0, maxLength);
  return (
    <>
      {final_tags.map((tag) => (
        <MuiTypography
          color='#7A7F86'
          key={tag}
          marginBottom={0.5}
          marginRight={0.5}
          sx={{
            backgroundColor: '#E8EDF1',
            borderRadius: '1.5rem',
            border: '0.5rem solid #E8EDF1',
            textAlign: 'center',
            display: 'inline-block',
            maxWidth: '10ch',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          variant='body2'>
          {tag}
        </MuiTypography>
      ))}
      {tags_count !== undefined ? (
        <MuiTypography
          marginLeft={1.2}
          sx={{color: '#1D7DEE', textAlign: 'center', display: 'inline-block'}}
          variant='body2'>
          +{tags_count} more
        </MuiTypography>
      ) : (
        <></>
      )}
    </>
  );
};
