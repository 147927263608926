import {MuiIconButton, MuiSvgIcon} from '@alation/fabric';
import {CaretLeftIcon} from '@alation/icons';
import React, {useEffect, useState} from 'react';

import {CategoriesView} from './CategoriesView';
import {ConnectorDetailsView} from './ConnectorDetailsView';
import {ConnectorSectionsView} from './ConnectorSectionsView';
import {ErrorView} from './ErrorView';
import {SearchAndFilterContainerView} from './SearchAndFilterContainerView';
import {ConnectorApi} from '../api/connector_api';
import {HomePageData, ConnectorDetails, FilteredData} from '../models/connectors.interface';

import './ConnectorSectionView.less';

function ConnectorDistributionPortalApp(): JSX.Element {
  const [homePageData, setHomePageData] = useState<HomePageData>();
  const [searchResultsData, setSearchResultsData] = useState<undefined | FilteredData>();
  const [categoryResultsData, setCategoryResultsData] = useState<FilteredData>();
  const [shouldShowPanel, setShouldShowPanel] = useState(false);
  const [detailsPanelValues, setDetailsPanel] = useState<ConnectorDetails[]>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const loadPage = (): void => {
    ConnectorApi.getAllHomePageData()
      .then((data: HomePageData) => {
        setHomePageData(data);
        setSearchResultsData(undefined);
        setCategoryResultsData(undefined);
      })
      .catch((err: {response: {data: string}}) => {
        setErrorMessage(err.response.data);
      });
  };

  const setSearchResults = (data: undefined | FilteredData): void => {
    setSearchResultsData(data);
    setCategoryResultsData(undefined);
  };

  const setCategoryResults = (data: FilteredData): void => {
    setCategoryResultsData(data);
  };

  useEffect(() => loadPage(), []);

  const detailsPanel = (id: string): void => {
    ConnectorApi.getConnectorDetailsData(id)
      .then((data: readonly ConnectorDetails[]) => {
        setDetailsPanel(Object.assign([], data));
        setShouldShowPanel(true);
      })
      .catch((err: {response: {data: string}}) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleOnDetailsClose = (): void => {
    setShouldShowPanel(false);
    setDetailsPanel(undefined);
  };

  return errorMessage ? (
    <ErrorView errorMessage={errorMessage} />
  ) : homePageData ? (
    <div className='homepage-view'>
      <div className='Categories'>
        <CategoriesView homePageData={homePageData} />
      </div>

      <SearchAndFilterContainerView
        homePageData={homePageData}
        setErrorMessage={setErrorMessage}
        setSearchResults={setSearchResults}
        showSearchFilter={!categoryResultsData}
      />

      <div className='ConnectorsSection'>
        {categoryResultsData ? (
          <div className='back-button'>
            <MuiIconButton
              aria-label='Back'
              color='primary'
              onClick={() => setSearchResults(undefined)}
              sx={{fontSize: 18}}
              title='Back'>
              <MuiSvgIcon component={CaretLeftIcon} sx={{p: 1}} /> Back
            </MuiIconButton>
          </div>
        ) : null}
        <div className='connector-sections'>
          <ConnectorSectionsView
            homePageData={categoryResultsData ?? searchResultsData ?? homePageData}
            setCategoryResults={setCategoryResults}
            setErrorMessage={setErrorMessage}
            showDetailsView={detailsPanel}
            showViewAll={!categoryResultsData}
          />
        </div>
      </div>
      <div className='ConnectorDetails'>
        {detailsPanelValues ? (
          <ConnectorDetailsView
            detailsPanelValues={detailsPanelValues}
            onClose={handleOnDetailsClose}
            setErrorMessage={setErrorMessage}
            shouldShowPanel={shouldShowPanel}
          />
        ) : null}
      </div>
    </div>
  ) : (
    <></>
  );
}

export {ConnectorDistributionPortalApp};
