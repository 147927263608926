import {MuiTypography} from '@alation/fabric';
import React, {FC} from 'react';

import {TagsComponent} from './TagsComponent';

import './ConnectorSectionView.less';

export interface ConnectorTileProps {
  readonly id: string;
  readonly image_url: string;
  readonly name: string;
  readonly showDetailsView: (id: string) => void;
  readonly tags: readonly string[];
  readonly version_id: string;
}

export const ConnectorTile: FC<ConnectorTileProps> = ({name, image_url, version_id, tags, showDetailsView, id}) => {
  return (
    <div className='connector-card' onClick={() => showDetailsView(id)}>
      <div className='connector-icon'>
        <img src={image_url} />
      </div>
      <MuiTypography
        style={{overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', fontWeight: '900'}}
        variant='h6'>
        {name}
      </MuiTypography>
      <MuiTypography variant='body2'>{`V  ${version_id}`}</MuiTypography>
      <div className='connector-card-footer'>
        <TagsComponent tags={tags} />
      </div>
    </div>
  );
};
