// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/atlas_grotesk/AtlasGrotesk-Regular-App.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../font/atlas_grotesk/AtlasGrotesk-Bold-App.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../font/atlas_grotesk/AtlasGrotesk-Black-App.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'AtlasGrotesk';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'AtlasGrotesk-Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'AtlasGrotesk-Black';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'AtlasGrotesk',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'AtlasGrotesk',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,+DAAiF;EACjF,kBAAkB;EAClB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,gCAAgC;EAChC,+DAA8E;EAC9E,kBAAkB;EAClB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,iCAAiC;EACjC,+DAA+E;EAC/E,kBAAkB;EAClB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT;cACY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb","sourcesContent":["@font-face {\n  font-family: 'AtlasGrotesk';\n  src: url('../font/atlas_grotesk/AtlasGrotesk-Regular-App.ttf') format('truetype');\n  font-style: normal;\n  font-weight: 400;\n  text-rendering: optimizeLegibility;\n}\n\n@font-face {\n  font-family: 'AtlasGrotesk-Bold';\n  src: url('../font/atlas_grotesk/AtlasGrotesk-Bold-App.ttf') format('truetype');\n  font-style: normal;\n  font-weight: 700;\n  text-rendering: optimizeLegibility;\n}\n\n@font-face {\n  font-family: 'AtlasGrotesk-Black';\n  src: url('../font/atlas_grotesk/AtlasGrotesk-Black-App.ttf') format('truetype');\n  font-style: normal;\n  font-weight: 900;\n  text-rendering: optimizeLegibility;\n}\n\nhtml {\n  font-size: 62.5%;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'AtlasGrotesk',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'AtlasGrotesk',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
