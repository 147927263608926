import {MuiCheckbox, MuiFormControlLabel, MuiFormGroup, MuiTypography} from '@alation/fabric';
import React, {FC, ChangeEvent} from 'react';

import './TagsFilterView.less';
import {HomePageData, Tags} from '../models/connectors.interface';

export interface TagsFilterViewProps {
  readonly homePageData: HomePageData;
  readonly onFilterChange: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly showSearchFilter: boolean;
}

export const TagsFilterView: FC<TagsFilterViewProps> = ({homePageData, onFilterChange, showSearchFilter}) => {
  return (
    <>
      {showSearchFilter ? (
        <div className='tags' data-testid='tags-filter'>
          <MuiFormGroup row>
            {homePageData?.tags?.map((value: Tags) => (
              <MuiFormControlLabel
                control={<MuiCheckbox />}
                data-testid={value.tag_name}
                key={value.tag_name}
                label={<MuiTypography variant='body0'>{value.tag_name_to_show}</MuiTypography>}
                labelPlacement='end'
                onChange={(e: any) => onFilterChange(e)}
                value={value.tag_name}
              />
            ))}
          </MuiFormGroup>
        </div>
      ) : null}
    </>
  );
};
