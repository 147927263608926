// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
  text-align: left;
  margin-left: 265px;
  padding-top: 30px;
}
.connector-grid {
  display: grid;
  grid-template-columns: repeat(4, 224.38px);
  grid-auto-rows: 235px;
  column-gap: 31px;
  margin-left: 285px;
}
@media only screen and (max-width: 1000px) {
  .connector-grid {
    grid-template-columns: repeat(2, 350);
  }
}
@media only screen and (max-width: 800px) {
  .connector-grid {
    grid-template-columns: 350px;
  }
}
.connector-card {
  width: 224.38px;
  height: 211px;
  text-align: left;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E8EDF1;
  border-radius: 6px;
  padding: 20px;
}
.connector-card:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 20px;
}
.connector-icon > img {
  border-radius: 10px;
  height: 40px;
}
.connector-icon {
  margin-bottom: 5px;
}
.connector-card-footer {
  flexWrap: 'wrap';
  flexDirection: 'row';
  flex: 1;
  width: 200px;
  padding-top: 45px;
}
.view-all {
  text-align: center;
  border-radius: 8px;
  padding-top: 58px;
  border: #E8EDF1;
}
.homepage-view {
  background-color: #F9F9FB;
  height: 100%;
}
.ConnectorsSection {
  background-color: #F9F9FB;
}
`, "",{"version":3,"sources":["webpack://./src/components/ConnectorSectionView.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,kBAAA;EACA,iBAAA;AADF;AAIA;EACE,aAAA;EACA,0CAAA;EACA,qBAAA;EACA,gBAAA;EACA,kBAAA;AAFF;AAKA;EACE;IACE,qCAAA;EAHF;AACF;AAMA;EACE;IACE,4BAAA;EAJF;AACF;AAOA;EACE,eAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;AALF;AAQA;EACE,eAAA;EACA,6CAAA;AANF;AASA;EACE,mBAAA;EACA,YAAA;AAPF;AAUA;EACE,kBAAA;AARF;AAWA;EACE,gBAAA;EACA,oBAAA;EACA,OAAA;EACA,YAAA;EACA,iBAAA;AATF;AAYA;EACE,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AAVF;AAaA;EACE,yBAAA;EACA,YAAA;AAXF;AAcA;EACE,yBAAA;AAZF","sourcesContent":["@drawer_width: 245px;\n\n.back-button {\n  text-align: left;\n  margin-left: @drawer_width + 20px;\n  padding-top: 30px;\n}\n\n.connector-grid {\n  display: grid;\n  grid-template-columns: repeat(4, 224.38px);\n  grid-auto-rows: 235px;\n  column-gap: 31px;\n  margin-left: @drawer_width + 40px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .connector-grid {\n    grid-template-columns: repeat(2, 350);\n  }\n}\n\n@media only screen and (max-width: 800px) {\n  .connector-grid {\n    grid-template-columns: 350px;\n  }\n}\n\n.connector-card {\n  width: 224.38px;\n  height: 211px;\n  text-align: left;\n  box-sizing: border-box;\n  background: #FFFFFF;\n  border: 1px solid #E8EDF1;\n  border-radius: 6px;\n  padding: 20px\n}\n\n.connector-card:hover {\n  cursor: pointer;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 20px;\n}\n\n.connector-icon > img {\n  border-radius: 10px;\n  height: 40px\n}\n\n.connector-icon {\n  margin-bottom: 5px;\n}\n\n.connector-card-footer {\n  flexWrap: 'wrap';\n  flexDirection: 'row';\n  flex: 1;\n  width: 200px;\n  padding-top: 45px;\n}\n\n.view-all {\n  text-align: center;\n  border-radius: 8px;\n  padding-top: 58px;\n  border: #E8EDF1;\n}\n\n.homepage-view {\n  background-color: #F9F9FB;\n  height: 100%;\n}\n\n.ConnectorsSection {\n  background-color: #F9F9FB;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
