// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-connector-icon > img {
  height: 100%;
  border-radius: 10px;
}
.details-connector-icon {
  margin-bottom: 10px;
  padding-left: 20px;
  padding-top: 108px;
  text-align: left;
}
.details-connector-tags {
  padding-left: 20px;
  padding-top: 20px;
}
.details-buttons {
  text-align: left;
  border-radius: 8px;
  padding-left: 20px;
  padding-top: 37px;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/components/ConnectorDetailsView.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;AACF;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AAAF;AAGA;EACE,kBAAA;EACA,iBAAA;AADF;AAIA;EACE,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;AAFF","sourcesContent":[".details-connector-icon > img {\n  height: 100%;\n  border-radius: 10px;\n}\n\n.details-connector-icon {\n  margin-bottom: 10px;\n  padding-left: 20px;\n  padding-top: 108px;\n  text-align: left;\n}\n\n.details-connector-tags{\n  padding-left: 20px;\n  padding-top: 20px;\n}\n\n.details-buttons {\n  text-align: left;\n  border-radius: 8px;\n  padding-left: 20px;\n  padding-top: 37px;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
