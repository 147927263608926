import axios, {AxiosResponse, AxiosInstance} from 'axios';

import {HomePageData, ConnectorDetails, FilteredData} from '../models/connectors.interface';

const instance: AxiosInstance = axios.create({
  timeout: 15000,
});

const responseBody = (response: AxiosResponse): any => response.data;

const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: any) => instance.post(url, body).then(responseBody),
};

export const ConnectorApi = {
  getConnectorDetailsData: (id: string): Promise<readonly ConnectorDetails[]> =>
    requests.get(`/api/connector_portal/get/details/${id}/`),
  getAllHomePageData: (): Promise<HomePageData> => requests.get('/api/connector_portal/all/'),
  getCategoryWiseConnectorData: (key: string): Promise<FilteredData> =>
    requests.get(`/api/connector_portal/search/?category=${key}`),
  getConnectorZip: (id: any): Promise<string> => requests.get(`/api/connector_portal/download/${id}/`),
  getFilteredConnectorData: (url: string): Promise<FilteredData> => requests.get(url),
};
