import {MuiTypography} from '@alation/fabric';
import React, {FC} from 'react';

export interface ErrorViewViewProps {
  readonly errorMessage: string;
}

export const ErrorView: FC<ErrorViewViewProps> = ({errorMessage}) => {
  return (
    <>
      <MuiTypography variant='h3'>{errorMessage}</MuiTypography>
    </>
  );
};
