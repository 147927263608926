import React, {FC} from 'react';

import './ConnectorSectionView.less';
import {ConnectorTile} from './ConnectorTile';
import {Connector} from '../models/connectors.interface';

export interface ConnectorGridProps {
  readonly connectors: readonly Connector[];
  readonly showDetailsView: (id: string) => void;
}

export const ConnectorGrid: FC<ConnectorGridProps> = ({connectors, showDetailsView}) => {
  return (
    <div className='connector-grid'>
      {connectors.map((connector: Connector) => {
        return (
          <ConnectorTile
            id={connector.id}
            image_url={connector.image_url}
            key={connector.id}
            name={connector.name}
            showDetailsView={showDetailsView}
            tags={connector.tags}
            version_id={connector.version_id}
          />
        );
      })}
    </div>
  );
};
