import {MuiDrawer, MuiIconButton, MuiSvgIcon, MuiButton, MuiTypography, MuiSelect, MuiMenuItem} from '@alation/fabric';
import {SmallTimesIcon, DownloadIcon, ExternalLinkIcon} from '@alation/icons';
import React, {Dispatch, FC, SetStateAction, useState} from 'react';

import {DetailsTagsComponent} from './DetailsTagsComponent';
import './ConnectorDetailsView.less';
import {ConnectorApi} from '../api/connector_api';
import {ConnectorDetails} from '../models/connectors.interface';

export interface ConnectorDetailsViewProps {
  readonly detailsPanelValues: readonly ConnectorDetails[];
  readonly onClose: () => void;
  readonly setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  readonly shouldShowPanel: boolean;
}

export const ConnectorDetailsView: FC<ConnectorDetailsViewProps> = ({
  detailsPanelValues,
  shouldShowPanel,
  onClose,
  setErrorMessage,
}) => {
  const [selectedVersion, setSelectedVersion] = useState(0);
  const handleGetConnectorZip = (): void => {
    ConnectorApi.getConnectorZip(currentVersion.id)
      .then((data: string) => {
        window.open(data, '_blank');
      })
      .catch((err: {response: {data: string}}) => {
        setErrorMessage(err.response.data);
      });
  };

  const openUrlAt = (url: string, target = '_self'): void => {
    window.open(url, target);
  };

  const currentVersion = detailsPanelValues[selectedVersion];
  return (
    <div data-testid='details-panel' key='right'>
      <MuiDrawer
        anchor={'right'}
        open={shouldShowPanel}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '337px',
            boxSizing: 'border-box',
          },
        }}>
        <MuiIconButton
          aria-label='Close'
          color='black'
          data-testid='details-panel-close'
          onClick={onClose}
          sx={{position: 'fixed', top: 29, right: 10, zIndex: 2000}}
          title='Close'>
          <MuiSvgIcon component={SmallTimesIcon} sx={{fontSize: 18}} />
        </MuiIconButton>
        <div className='details-connector-icon' data-testid='details-panel-image'>
          <img src={currentVersion.image_url} />
        </div>
        <div style={{paddingLeft: '20px'}}>
          <MuiTypography variant='h5'>{currentVersion.name}</MuiTypography>
          {selectedVersion === 0 ? (
            <div data-testid='latest-tag'>
              <MuiTypography
                key={'LATEST'}
                sx={{
                  marginTop: '0.8rem',
                  padding: '3px',
                  borderRadius: '9px',
                  color: '#fff',
                  backgroundColor: '#0075E3',
                  display: 'inline-block',
                }}
                variant='body0'>
                {'LATEST'}
              </MuiTypography>
            </div>
          ) : null}

          <MuiTypography marginBottom={1} marginTop={1} variant='body1'>
            {`V  ${currentVersion.version_id}`}
          </MuiTypography>

          {detailsPanelValues.length > 1 ? (
            <div data-testid='version-select-dropdown'>
              <MuiSelect
                onChange={(e) => {
                  setSelectedVersion((e.target.value as number) - 1);
                }}
                value={0}>
                <MuiMenuItem disabled={true} key={'default'} value={0}>
                  Other versions
                </MuiMenuItem>
                {detailsPanelValues
                  .map((connectorDetails, index) => {
                    return index !== selectedVersion ? (
                      <MuiMenuItem
                        key={connectorDetails.version_id}
                        value={index + 1}>{`V  ${connectorDetails.version_id}`}</MuiMenuItem>
                    ) : null;
                  })
                  .sort()}
              </MuiSelect>
            </div>
          ) : null}
        </div>
        <div className='details-connector-tags'>
          <DetailsTagsComponent tags={currentVersion.tags} />
        </div>
        <div>
          {currentVersion.connector_doc_url ? (
            <div className='details-buttons'>
              <MuiButton
                data-testid='documentation-btn'
                endIcon={<MuiSvgIcon component={ExternalLinkIcon} sx={{width: 14, height: 14}} />}
                id='documentation-btn'
                onClick={() => openUrlAt(currentVersion.connector_doc_url, '_blank')}
                sx={{width: 152, height: 32}}
                variant='outlined'>
                <MuiTypography sx={{color: '#0075E3'}} variant='h6'>
                  Documentation
                </MuiTypography>
              </MuiButton>
            </div>
          ) : null}
          <div className='details-buttons'>
            <MuiButton
              data-testid='download-btn'
              id='download-btn'
              onClick={handleGetConnectorZip}
              startIcon={<MuiSvgIcon component={DownloadIcon} sx={{width: 14, height: 14}} />}
              sx={{width: 134, height: 32}}
              variant='contained'>
              <MuiTypography sx={{color: '#FFFFFF'}} variant='h6'>
                Download
              </MuiTypography>
            </MuiButton>
          </div>
        </div>
        <div className='details-compatibility'>
          <MuiTypography marginLeft={2} marginTop={2.6} sx={{fontFamily: 'AtlasGrotesk-Bold'}} variant='body1'>
            Compatibility
          </MuiTypography>
          <MuiTypography marginLeft={2} marginTop={0.5} variant='body1'>
            Compatible with Alation version(s) or higher: {currentVersion.supported_alation_versions.join(', ')}
          </MuiTypography>
        </div>
        {currentVersion.notes ? (
          <div className='details-notes' data-testid='details-panel-notes'>
            <MuiTypography marginLeft={2} marginTop={2.6} sx={{fontFamily: 'AtlasGrotesk-Bold'}} variant='body1'>
              Notes
            </MuiTypography>
            <MuiTypography marginLeft={2} marginTop={0.5} variant='body1'>
              {currentVersion.notes}
            </MuiTypography>{' '}
          </div>
        ) : null}
        <div className='details-info'>
          <MuiTypography marginLeft={2} marginTop={2.6} sx={{fontFamily: 'AtlasGrotesk-Bold'}} variant='body1'>
            Info
          </MuiTypography>
          <MuiTypography marginLeft={2} marginTop={0.5} variant='body1'>
            {currentVersion.description}
          </MuiTypography>
        </div>
        <div className='details-usage'>
          <MuiTypography marginLeft={2} marginTop={2.6} sx={{fontFamily: 'AtlasGrotesk-Bold'}} variant='body1'>
            Usage History
          </MuiTypography>
          <MuiTypography data-testid='download-count-text' marginLeft={2} marginTop={0.5} variant='body1'>
            This connector has been downloaded {currentVersion.download_count}{' '}
            {currentVersion.download_count === 1 ? 'time' : 'times'} at your organization.
          </MuiTypography>
        </div>
      </MuiDrawer>
    </div>
  );
};
