import {MuiTypography} from '@alation/fabric';
import React, {FC} from 'react';
import './ConnectorSectionView.less';

export interface DetailsTagsComponentProps {
  readonly tags: readonly string[];
}

export const DetailsTagsComponent: FC<DetailsTagsComponentProps> = ({tags}) => {
  return (
    <>
      {tags.map((tag) => (
        <MuiTypography
          key={tag}
          marginRight={1}
          marginTop={1}
          sx={{
            backgroundColor: '#E8EDF1',
            border: '5px solid #E8EDF1',
            borderRadius: '50px',
            display: 'inline-block',
            maxWidth: '15ch',
          }}
          variant='body2'>
          {tag}
        </MuiTypography>
      ))}
    </>
  );
};
