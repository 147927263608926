import {MuiDrawer, MuiList, MuiListItem, MuiListItemButton, MuiTypography} from '@alation/fabric';
import React, {FC} from 'react';

import {HomePageData} from '../models/connectors.interface';

export interface CategoriesViewProps {
  readonly homePageData: HomePageData;
}

export const CategoriesView: FC<CategoriesViewProps> = ({homePageData}) => {
  const handleClickScroll = (sectionId: string): void => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  };

  const hoverCategoryName = {
    '&:hover': {
      cursor: 'pointer',
      color: '#000000',
    },
  };

  return (
    <>
      <MuiDrawer
        anchor='left'
        sx={{
          width: '245px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '245px',
            boxSizing: 'border-box',
          },
        }}
        variant='permanent'>
        <MuiTypography marginBottom={2.3} marginLeft={5.2} marginTop={13} variant='h5'>
          Categories
        </MuiTypography>
        <MuiList
          sx={{
            ml: '26px',
          }}>
          {homePageData.category_types.map((text: string) => (
            <MuiListItem disablePadding key={text} sx={{paddingBottom: '6px'}}>
              <MuiListItemButton
                onClick={() => {
                  handleClickScroll(text);
                }}>
                <MuiTypography color='text.secondary' sx={hoverCategoryName} variant='body1'>
                  {text}
                </MuiTypography>
              </MuiListItemButton>
            </MuiListItem>
          ))}
        </MuiList>
      </MuiDrawer>
    </>
  );
};
